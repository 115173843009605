import { Pipe, PipeTransform } from '@angular/core';
import { AuthService } from '@services/auth.service';
import { PermissionRolesMap } from '@models/permissions.db';
import { Permissions } from '@models/permissions.enum';

@Pipe({
  name: 'hasPermission',
  standalone: true
})
export class PermissionPipe implements PipeTransform {

  transform(permission: Permissions): boolean {
    return this.hasPermission(permission);
  }

  constructor(
    private authService: AuthService,
  ) { }

  private hasPermission(permission: Permissions) {
    const userRoles = this.authService.roles;

    const requiredRoles = PermissionRolesMap[permission];
    return requiredRoles.some(role => userRoles.includes(role));
  }
}
