import { PaginationResponse } from './models/pagination.model';
import { Injectable } from '@angular/core';
import { CrudService } from './crud.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StudyParticipantStatus } from './models/session.model';
import { Participant } from './models/participant.model';

@Injectable({
  providedIn: 'root'
})
export class ParticipantsService extends CrudService<Participant> {

  public override domainEntity = 'participants';

  constructor(public override http: HttpClient) {
    super(http);
    this.setSourceService();
  }

  getByStudyAndSite(
    study: number,
    site: number,
    status = StudyParticipantStatus.Active
  ): Observable<PaginationResponse<Participant>> {
    const size = 99999
    return this.getAll({ study, site, status, size })
  }

  getBySite(
      site: number,
      status = StudyParticipantStatus.Active
  ): Observable<PaginationResponse<Participant>> {
    const size = 99999
    return this.getAll({ site, status, size })
  }
}
